<template>
  <div>
    <el-row :gutter="0">
      <el-col :span="20" :offset="2">
        <custom-header :title="'Барааны үнэ өөрчлөх'" />
        <el-row :gutter="20">
          <el-col :span="16">
            <div class="panel">
              <div class="panel-item">
                <el-row :gutter="20" type="flex">
                  <el-col :span="16">
                    <el-select v-loading="isFullLoading" placeholder="Брэнд хайх ..." v-model="selectBrand" filterable multiple class="block" @change="setCurrentBrand">
                      <el-option
                        v-for="(brand, indexBrand) in brandOptions"
                        :key="indexBrand"
                        :label="brand.brand_monName"
                        :value="brand.brand_id"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-button v-if="selectBrand.length > 0" class="block" @click="visibleProductDialog = true">Брэнд сонгох</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="panel">
          <div class="panel-item">
            <el-row :gutter="20">
              <el-table
                :data="selectData"
                style="width: 100%"
                default-expand-all>
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <el-table size="small" :data="props.row.variant.filter(data => data.checked === true ? data : '')">
                      <el-table-column label="Хувилбарын зураг" align="center">
                        <template slot-scope="props"><img :src="`${Array.isArray(props.row.variant_image) ? props.row.variant_image[0] : props.row.variant_image}`" alt="" style="width:50px"></template>
                      </el-table-column>
                      <el-table-column prop="variant_name" label="Төрөл"></el-table-column>
                      <el-table-column label="Харилцагчийн SKU">
                        <template>
                          <span>{{props.row.supplier_sku}}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Борлуулах үнэ">
                        <template slot-scope="scope">
                          <el-input-number v-model="scope.row.variant_sellPrice" controls-position="right" type="small"></el-input-number>
                        </template>
                      </el-table-column>
                      <el-table-column label="Шимтгэлийн хувь">
                        <template>
                          <span>{{props.row.brand_fee_percent}}%</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Тооцоо нийлэх үнэ">
                          <template slot-scope="scope">
                            <el-input disabled type="number" :value="scope.row.variant_sellPrice - scope.row.variant_sellPrice * props.row.brand_fee_percent / 100">
                          <template slot="append">₮</template>
                          </el-input>
                          </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Бүтээгдэхүүний зураг">
                  <template slot-scope="scope">
                    <img :src="`${scope.row.image}`" alt="" style="width: 50px">
                  </template>
                </el-table-column>
                <el-table-column
                  prop="brand_name"
                  label="Брэнд">
                </el-table-column>
                <el-table-column
                  prop="name_mon"
                  label="Бүтээгдэхүүн">
                </el-table-column>
                <el-table-column align="center">
                  <template slot-scope="scope">
                    <el-button
                      @click.native.prevent="deleteRow(scope.$index, selectData)"
                      type="danger"
                      size="small"
                      icon="el-icon-delete">
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
        </div>
        <el-row class="text-right" :gutter="0">
          <el-button @click="$router.go(-1)">Буцах</el-button>
          <el-button type="success" @click="acceptedPrices">Хадгалах</el-button>
        </el-row>
      </el-col>
    </el-row>
    <el-dialog
      title="Брэнд сонгох"
      :visible.sync="visibleProductDialog"
      top="10vh"
      width="500"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input
            placeholder="Бүтээгдэхүүний нэр"
            v-model="searchText"
            @change="onSearch"
          >
            <template slot="prepend"><i class="el-icon-search"></i></template>
          </el-input>
        </el-col>
      </el-row>
      <el-row class="mt20" v-loading="isLoading">
        <ul class="transfer-product-list" v-if="products && products.length > 0">
          <li v-for="(product, index) in products" :key="index">
            <el-row :gutter="10" type="flex" align="middle">
              <el-col :span="2">
                <div class="image-holder">
                  <img :src="Array.isArray(product.image) ? product.image[0] : product.image" alt="">
                </div>
              </el-col>
              <el-col :span="20">
                {{product.name_mon}}
              </el-col>
              <el-col :span="2" class="text-right">
                <el-checkbox v-model="product.checked" :indeterminate="product.isIndeterminate" @change="checkProduct(product)"></el-checkbox>
              </el-col>
            </el-row>
            <ul>
              <li v-for="(variant, index1) in product.variant" :key="index1">
                <el-row :gutter="20" type="flex" align="middle">
                  <el-col :span="18" :offset="2">
                    <div>
                      {{variant.variant_name}}
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="text-center">
                      {{variant.count}}
                    </div>
                  </el-col>
                  <el-col :span="2" class="text-right">
                    <el-checkbox v-model="variant.checked" @change="checkVariant(product)"></el-checkbox>
                  </el-col>
                </el-row>
              </li>
            </ul>
          </li>
        </ul>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-pagination
          @current-change = "curentPageHandler"
          @size-change = "sizeChangeHandler"
          class="text-right mt10 mb20"
          background
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[5, 20, 50, 100]"
          layout="total, sizes, prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
        <el-button @click="visibleProductDialog = false">Буцах</el-button>
        <el-button @click="checkedOrders(products)" type="primary">Сонгох</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import customHeader from '../../../components/common/customHeader'
import { getSupplierId } from '../../../utils/auth'
import services from '../../../helpers/services'
export default {
  name: 'addPrice',
  components: {
    customHeader
  },
  data () {
    return {
      pageSize: 20,
      currentPage: 1,
      isLoading: false,
      isFullLoading: false,
      totalCount: 0,
      products: null,
      selectBrand: [],
      searchText: '',
      selectData: [],
      visibleProductDialog: false,
      brandOptions: [],
      variants: []
    }
  },
  created () {
    this.getBrand()
  },
  methods: {
    checkVariant (product) {
      let checkedCount = 0
      product.variant.forEach(variant => {
        if (variant.checked) {
          checkedCount = checkedCount + 1
        }
      })
      product.checked = checkedCount === product.variant.length
      product.isIndeterminate = checkedCount > 0 && checkedCount < product.variant.length
    },

    deleteRow (index, rows) {
      rows.splice(index, 1)
    },

    async setCurrentBrand (brand) {
      if (this.selectBrand.length > 0) {
        this.getProductsWithVariant(await getSupplierId(), brand, (this.pageSize * (this.currentPage - 1)), this.pageSize, this.searchText)
      }
    },

    async onSearch () {
      this.getProductsWithVariant(await getSupplierId(), this.selectBrand, (this.pageSize * (this.currentPage - 1)), this.pageSize, this.searchText)
    },

    async sizeChangeHandler (item) {
      this.pageSize = item
      this.getProductsWithVariant(await getSupplierId(), this.selectBrand, (this.pageSize * (this.currentPage - 1)), this.pageSize, this.searchText)
    },

    async curentPageHandler (item) {
      this.currentPage = item
      this.getProductsWithVariant(await getSupplierId(), this.selectBrand, (this.pageSize * (this.currentPage - 1)), this.pageSize, this.searchText)
    },
    acceptedPrices () {
      this.isLoading = true
      const payload = {
        accepted_prices: []
      }
      this.selectData.forEach(product => {
        product.variant.forEach(variant => {
          const tempBody = {}
          if (product) {
            tempBody.discounted_price = 0
            tempBody.brand_id = product.brand_id ? product.brand_id : ''
            tempBody.supplier_id = product.supplier_id ? product.supplier_id : ''
            tempBody.get_price = variant.variant_getPrice ? parseInt(variant.variant_getPrice) : 0
            tempBody.product_id = product.productId ? product.productId : ''
            tempBody.product_name = product.name_mon ? product.name_mon : ''
            tempBody.sell_price = variant.variant_sellPrice ? parseInt(variant.variant_sellPrice) : 0
            tempBody.variant_id = variant.variant_id ? variant.variant_id : ''
            tempBody.brand_fee = product.brand_fee_percent ? parseInt(product.brand_fee_percent) : 0
            tempBody.start_date = null
            tempBody.end_date = null
          }
          payload.accepted_prices.push(tempBody)
        })
      })
      services.acceptedPrices(payload).then((response) => {
        if (response.status === 'success') {
          this.isLoading = false
          this.$router.push({
            name: 'products'
          })
          this.$notify({
            title: 'Амжилттай',
            message: response.message,
            type: 'success'
          })
        } else if (response.status === 'error') {
          this.isLoading = false
          this.$notify({
            title: 'Алдаа',
            message: response.message,
            type: 'error'
          })
        }
      })
    },
    async getProductsWithVariant (supplierIds, brand, from, size, search) {
      this.isLoading = true
      const body = {
        brand_id: brand,
        status: '',
        search_text: search,
        supplier_id: supplierIds,
        size: size,
        from: from
      }
      const promise = new Promise((resolve) => {
        services.getProductsWithVariant(body).then(response => {
          this.isLoading = false
          if (response.status === 'success') {
            this.visibleProductDialog = true
            this.totalCount = response.count
            response.data.forEach(product => {
              const property = {
                checked: false,
                isIndeterminate: false
              }
              Object.assign(product, property, product)
              product.variant.forEach(variant => {
                const property = {
                  checked: false,
                  number_tobe_transferred: 0
                }
                Object.assign(variant, property, variant)
              })
              product.variant.forEach(el => {
                this.variants.push(el)
              })
            })
          } else {
            this.totalCount = 0
            this.visibleProductDialog = false
          }
          this.products = response.data
          resolve()
        })
      })
      await promise
    },

    checkedOrders (product) {
      const tempProducts = []
      product.forEach(data => {
        const variants = [...data.variant.filter(el => el.checked)]
        if (variants.length > 0) {
          data.variant = variants
          tempProducts.push(data)
        }
      })
      if (tempProducts.length > 0) {
        this.selectData = tempProducts
        this.visibleProductDialog = false
      }
    },
    checkProduct (product) {
      product.variant.forEach(variant => {
        variant.checked = product.checked
      })
    },
    getBrand () {
      this.isFullLoading = true
      const query = '?size=1000' + '&from=0'
      services.getBrand(query).then(response => {
        if (response.status === 'success' && response.data) {
          this.brandOptions = response.data
          this.isFullLoading = false
        } else {
          this.isFullLoading = false
        }
      })
    }
  }
}
</script>
